import {
    Home,
    Box,
    DollarSign,
    Tag,
    Clipboard,
    ShoppingBag,
    Truck,
    Camera,
    AlignLeft,
    UserPlus,
    Users,
    Chrome,
    BarChart,Settings,Archive, LogIn
} from 'react-feather';

export const MENUITEMS = [
    {
        path: '/orders', title: 'Order Management', icon: Home, type: 'link', badgeType: 'primary', active: false
    },
    {
        path: '/topics', title: 'Topics', icon: Tag, type: 'link', badgeType: 'primary', active: false
    },
    {
        path: '/store', title: 'Store Management', icon: ShoppingBag, type: 'link', badgeType: 'primary', active: false
    },
    {
        path: '/blogs', title: 'Blogs', icon: Clipboard, type: 'link', badgeType: 'primary', active: false
    },
    {
        title: 'Products', icon: Box, type: 'sub', active: false, children: [
            // {
            //     title: 'Physical', type: 'sub', active: false, children: [
            //         { path: '/products/physical/category', title: 'Category', type: 'link' },
            //         { path: '/products/physical/sub-category', title: 'Sub Category', type: 'link' },
            //         { path: '/products/physical/product-list', title: 'Product List', type: 'link' },
            //         { path: '/products/physical/product-detail', title: 'Product Detail', type: 'link' },
            //         { path: '/products/physical/add-product', title: 'Add Product', type: 'link' },
            //     ]
            // },
            // {
            //     title: 'digital', type: 'sub', active: false, children: [
            //         { path: '/products/digital/digital-category', title: 'Category', type: 'link' },
            //         { path: '/products/digital/digital-sub-category', title: 'Sub Category', type: 'link' },
            //         { path: '/products/digital/digital-product-list', title: 'Product List', type: 'link' },
            //         { path: '/products/digital/digital-add-product', title: 'Add Product', type: 'link' },
            //     ]
            // },
            {
                title: 'brands', path:'/products/brands', icon: Camera, type: 'link', active: false
            },
            {
                title: 'unit', path:'/products/unit', icon: Camera, type: 'link', active: false
            },
            {
                title: 'Category', path:'/products/category', icon: Camera, type: 'link', active: false
            },
            {
                title: 'Sub Category', path:'/products/subcategory', icon: Camera, type: 'link', active: false
            },
            {
                title: 'Featured', path:'/products/featured', icon: Camera, type: 'link', active: false
            },
            {
                title: 'Add Product', path:'/products/add-product', icon: Camera, type: 'link', active: false
            },
            {
                title: 'Product List', path:'/products/products', icon: Camera, type: 'link', active: false
            },
            {
                 path:`/products/EditProduct/:id`, active: false
            },
            
            
            
        ]
    },
    {
        title: 'Customer', icon: Chrome, type: 'sub', children: [
            { path: '/Customer/Customerlist', title: 'Customer', type: 'link' },
            
            
        ]
    },
    {
        path: '/usermangement', title: 'User managment', icon: Clipboard, type: 'link', badgeType: 'primary', active: false
    },
    // {
        
    //     title: 'Sales', icon: DollarSign, type: 'sub', active: false, children: [
    //         { path: '/sales/orders', title: 'Orders', type: 'link' },
    //         { path: '/sales/transactions', title: 'Transactions', type: 'link' },
    //     ]
    // },
    // {
    //     title: 'Coupons', icon: Tag, type: 'sub', active: false, children: [
    //         { path: '/coupons/list-coupons', title: 'List Coupons', type: 'link' },
    //         { path: '/coupons/create-coupons', title: 'Create Coupons', type: 'link' },
    //     ]
    // },
    // {
    //     title: 'Pages', icon: Clipboard , type: 'sub', active: false, children: [
    //         { path: '/pages/list-page', title: 'List Page', type: 'link' },
    //         { path: '/pages/create-page', title: 'Create Page', type: 'link' },
    //     ]
    // },
    // {
    //     title: 'Media', path: '/media', icon: Camera, type: 'link', active: false
    // },
    // {
    //     title: 'Menus', icon: AlignLeft, type: 'sub', active: false, children: [
    //         { path: '/menus/list-menu', title: 'List Menu', type: 'link' },
    //         { path: '/menus/create-menu', title: 'Create Menu', type: 'link' },
    //     ]
    // },
    // {
    //     title: 'Users', icon: UserPlus, type: 'sub', active: false, children: [
    //         { path: '/users/list-user', title: 'User List', type: 'link' },
    //         { path: '/users/create-user', title: 'Create User', type: 'link' },
    //     ]
    // },
    // {
    //     title: 'Vendors', icon: Users, type: 'sub', active: false, children: [
    //         { path: '/vendors/list_vendors', title: 'Vendor List', type: 'link' },
    //         { path: '/vendors/create-vendors', title: 'Create Vendor', type: 'link' },
    //     ]
    // },
    // {
    //     title: 'Order', icon: Chrome, type: 'sub', children: [
    //         { path: '/order/orderDetails', title: 'Order', type: 'link' },
    //         { path:`/order/orderCustomerDetails:id`},
            
    //     ]
    // },
    // {
    //     title: 'Reports',path:'/reports/report', icon: BarChart, type: 'link', active: false
    // },
   
    {
        title: 'Settings', icon: Settings, type: 'sub', children: [
            { path: '/settings/profile', title: 'Profile', type: 'link' },
            { path: '/settings/notification', title: 'Notification', type: 'link' },
            { path: '/settings/slider', title: 'Slider', type: 'link' },
            { path: '/settings/delivery', title: 'Delivery', type: 'link' },
            { path: '/settings/deliverySlot', title:'DeliverySlot', type: 'link' },
            { path: '/settings/holiday', title:'Holiday', type: 'link' },
            { path: '/settings/deliveryCharges', title:'Delivery Charges', type: 'link' },
            { path: '/settings/marketting', title:'Marketing', type: 'link' },
        ]
    },
    // {
    //     title: 'Invoice',path:'/invoice', icon: Archive, type: 'link', active: false
    // },
    // {
    //     title: 'Login',path:'/auth/login', icon: LogIn, type: 'link', active: false
    // }
]
