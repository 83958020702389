import React, { useState, useEffect, useCallback, useDebugValue } from "react";
import {
  Modal,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import SelectInput from "../common/SelectInput";
import {
  EditorderStatus,
  getOrderList,
} from "../../redux/actions/OrderActions";
import customAxios from "../../customAxios";
import { toast } from "react-toastify";

const AssignRiderModal = ({
  openModal,
  onCloseModal: closeModal,
  order_id,
  rider,
  assign,
  store
}) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");
  const [selectValue, setSelectValue] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {      
    if (rider) {
      setSelectValue({
        label: `${rider?.name} ${rider?.email} ${rider?.mobile}`,
        value: rider?._id,
      });
      setSelectedValue("rider", rider);

    } else {
      setSelectValue({
        label: `Select rider`,
        value: null,
      });
      setSelectedValue("rider", null);
    }
  }, [rider, closeModal]);

  useEffect(() => {
    if(assign) {
      getRiderList(store);
    }
  }, [assign]);

  const getRiderList = useCallback(async (data) => {
    try {
      const response = await customAxios.get(`admin/rider-list-store/` + data);
      if (response?.data?.data) {
        setOptions(
          response?.data?.data?.map(({ email, mobile, name, _id }) => ({
            label: `${name} ${email} ${mobile}`,
            value: _id,
          }))
        );
      }
    } catch (error) {
      // toast .show({
      //     type: 'error',
      //     text1: error
      // });
    }
    // setRiderList()
  }, []);

  const assignRider = useCallback(async () => {
    if (!value) {
      return toast.show({
        type: "error",
        text1: "Please choose a rider",
      });
    }

    try {
      setLoading(true);

      const response = await customAxios.post(`admin/order-assign`, {
        order_id: order_id,
        rider_id: value,
      });

      if (response?.data) {
        dispatch(getOrderList(1));
      }
    } catch (error) {
      // Toast.show({
      //     type: 'error',
      //     text1: error
      // });
    } finally {
      closeModal();
      setLoading(false);
    }
  }, [value, order_id]);

  const schema = Yup.object({
    rider: Yup.mixed()
      .required("Rider is required")
      .typeError("Rider is required"),
  });

  const {
    register,
    handleSubmit,
    setFieldValue,
    control,
    formState: { errors },
    reset,
    setValue: setSelectedValue,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onChange = (item) => {
    setValue(item?.value);
    setSelectValue(item);
    setSelectedValue("rider", item);
    setError("rider", { type: "custom", message: null });
  };

  return (
    <Modal isOpen={openModal} toggle={closeModal}>
      <ModalHeader toggle={closeModal}>
        <h5 className="modal-title f-w-600" id="exampleModalLabel2">
          Assign rider
        </h5>
      </ModalHeader>
      <ModalBody>
        <Form>
          {/* <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            <label>Current Status</label>
            <h1 style={{ fontSize: "12px" }}>{data?.orderStatus}</h1>
          </div> */}

          <SelectInput
            fieldLabel="Riders"
            control={control}
            error={errors.rider}
            selectvalue={selectValue}
            fieldName="rider"
            options={options}
            onChangeValue={onChange}
          />
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button
          type="button"
          color="primary"
          onClick={handleSubmit(assignRider)}
          disabled={loading}
        >
          Assign
        </Button>

        {/* <Button
          type="button"
          color="secondary"
          onClick={onCloseModal}
        >
          Close ̰
        </Button> */}
      </ModalFooter>
    </Modal>
  );
};

export default AssignRiderModal;
