import React, { useState, useRef, useEffect } from "react";
import {
  GoogleMap,
  LoadScript,
  Autocomplete,
  Polygon,
  DrawingManager,
  useJsApiLoader,
} from "@react-google-maps/api";
import { MAPS } from "../../config/constant";
import { Label } from "reactstrap";
import { Controller } from "react-hook-form";

const libraries = ["places", "drawing"];

const EditMap = ({ setValue, control, polygons = false, editable }) => {
  const [map, setMap] = useState(null);
  const [currentPosition, setCurrentPosition] = useState({ lat: 0, lng: 0 });
  const [storeLocation, setStoreLocation] = useState("");
  const [polygonCoords, setPolygonCoords] = useState([]);
  const [lastPolygon, setLastPolygon] = useState(null);
  const autocompleteRef = useRef(null);

  useEffect(() => {
    if (polygons) {
      setPolygonCoords(
        polygons?.map(([lat, lng]) => {
          return {
            lat,
            lng,
          };
        })
      );

      setCurrentPosition({
        lat: polygons[0][0],
        lng: polygons[0][1],
      });
    } else {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          setCurrentPosition({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        });
      }
    }
  }, [polygons]);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: MAPS,
    libraries,
  });

  const onPlaceChanged = () => {
    const place = autocompleteRef.current.getPlace();

    if (place && place.geometry) {
      const location = place.geometry.location;
      const lat = location.lat();
      const lng = location.lng();

      setStoreLocation(place.formatted_address);
      setValue("address", {
        address: place.formatted_address,
        latitude: lat,
        longitude: lng,
      });
    }
  };

  const onPolygonComplete = (polygon) => {
    const path = polygon
      .getPath()
      .getArray()
      .map((coord) => ({
        lat: coord.lat(),
        lng: coord.lng(),
      }));

    setValue("delivery_location", path.map(({ lat, lng }) => [lat, lng]));
    setPolygonCoords(path);

    // Remove the previously drawn polygon
    if (lastPolygon) {
      lastPolygon.setMap(null);
    }

    setLastPolygon(polygon);
  };

  const onPolygonDragEnd = (polygon) => {
    // Update the state with the new polygon coordinates after dragging
    const path = polygon
      .getPath()
      .getArray()
      .map((coord) => ({
        lat: coord.lat(),
        lng: coord.lng(),
      }));
    
    setPolygonCoords(path);
    setValue("delivery_location", path.map(({ lat, lng }) => [lat, lng]));
  };

  const onLoad = (polygon) => {
    setLastPolygon(polygon);
  };

  if (isLoaded) {
    return (
      <div>
        <Label style={{ marginBottom: 6 }}>Store Location</Label>
        <Controller
          name={"address"}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <>
              <Autocomplete
                onLoad={(ref) => (autocompleteRef.current = ref)}
                onPlaceChanged={onPlaceChanged}
              >
                <input
                  {...field}
                  value={field?.value?.address}
                  type="text"
                  placeholder="Enter a location"
                  style={{
                    width: "100%",
                    padding: "10px",
                    fontSize: "16px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                    transition: "border-color 0.2s, box-shadow 0.2s",
                  }}
                />
              </Autocomplete>

              {error && (
                <p
                  role="alert"
                  style={{
                    color: "red",
                    display: "flex",
                    flexDirection: "start",
                    paddingLeft: "10px",
                    fontSize: "12px",
                  }}
                >
                  {error?.message}
                </p>
              )}
            </>
          )}
        />
        <br />
        <label>Delivery Location</label>
        <Controller
          name={"delivery_location"}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <div>
              <GoogleMap
                onLoad={(mapInstance) => setMap(mapInstance)}
                mapContainerStyle={{ height: "400px", width: "100%" }}
                center={currentPosition}
                zoom={14}
                version="weekly"
              >
                {window.google && window.google.maps ? (
                  <DrawingManager
                    key={"233"}
                    onPolygonComplete={onPolygonComplete}
                    options={{
                      drawingControl: true,
                      drawingMode: "polygon", // Set the default drawing tool to polygon
                      drawingControlOptions: {
                        position: window.google.maps.ControlPosition.TOP_CENTER,
                        drawingModes: ["polygon"],
                      },
                      polygonOptions: {
                        fillColor: "lightblue",
                        fillOpacity: 0.5,
                        strokeColor: "blue",
                        strokeOpacity: 1,
                        strokeWeight: 2,
                      },
                    }}
                  />
                ) : (
                  <p>Loading drawing tools...</p>
                )}
                {polygonCoords.length > 0 && (
                  <Polygon
                    path={polygonCoords}
                    onLoad={onLoad}
                    onMouseUp={() => onPolygonDragEnd(lastPolygon)}
                    options={{
                      fillColor: "lightblue",
                      fillOpacity: 0.5,
                      strokeColor: "blue",
                      strokeOpacity: 1,
                      strokeWeight: 2,
                      editable: true, // Allow users to edit the polygon
                      draggable: true, // Allow users to drag the polygon
                    }}
                  />
                )}
              </GoogleMap>

              {error && (
                <p
                  style={{
                    color: "red",
                    display: "flex",
                    flexDirection: "start",
                    paddingLeft: "10px",
                    fontSize: "12px",
                  }}
                >
                  {error?.message}
                </p>
              )}
            </div>
          )}
        />
      </div>
    );
  }
};

export default EditMap;
