import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../common/breadcrumb";
import {
  Modal,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { filterOrder, getOrderList, getOrderStatus } from "../../redux/actions/OrderActions";
import DataTables from "../common/DataTables";
import { useNavigate } from "react-router-dom";
import OrderStatusModal from "./OrderStatusModal";
import { RESET_ERROR } from "../../redux/constants/userConstants";
import DataTable from "react-data-table-component";
import SearchHeader from "../common/header_components/searchHeader";
import CustomInput from "../common/CustomInputPast";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Reactotron from "reactotron-react-js"
import * as yup from "yup";

import moment from "moment";
import AssignRiderModal from "./AssignRider";
// getorderlist
const OrderDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { orderList, orderupdated, error } = useSelector(
    (state) => state.orders
  );
  const { loading } = useSelector((state) => state.auth);

  const [datas, setDatas] = useState([]);

  const [open, setOpen] = useState(false);
  const [assign, setAssign] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [activeData, setActiveData] = useState(null);
  const [search, setSearch] = useState([]);
  const [orderId, setOrderId] = useState('')
  const [store, setStore] = useState('')
  const [rider, setRider] = useState(null)


  useEffect(() => {
    if (orderList) {
      setDatas(orderList);
      // setSearch(orderList)
    }
  }, [orderList]);

  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const Navigatedetailpage = (id) => {
    navigate(`/order/orderCustomerDetails/${id}`);
  };
  const handleEdit = (data) => {
    setActiveData(data);
    setOpen(true);
  };

  const handleAssign = (data) => {
    setStore(data?.store)
    setOrderId(data?._id)
    if (data?.rider) {
      setRider(data?.rider)
    } else {
      setRider(null)
    }
  };

  useEffect(() => {
    if (orderId) {
      setAssign(true);
    }
  }, [rider, orderId])

  useEffect(() => {
    if (filterText) {
      let datas = orderList?.filter(
        (ord) =>
          ord?.customer?.name
            ?.toLowerCase()
            .includes(filterText.toLowerCase()) ||
            ord?.shippingAddress?.mobile
            ?.toString() // Convert mobile number to string
            .toLowerCase()
            .includes(filterText?.toLowerCase()) ||
          `#ORD${ord?.order_id}`
            ?.toLowerCase()
            ?.includes(filterText?.toLowerCase()) ||
          ord?.orderStatus?.toLowerCase()?.includes(filterText.toLowerCase())
      );

      setDatas(datas);
    }
  }, [filterText]);

  // const filterSerach = (value) => {
  //     setFilterText(value)
  //     let data = orderList?.filter(ord => ord?.customer?.name?.toLowerCase().includes(value.toLowerCase())|| ord?.shippingAddress[0]?.mobile?.toLowerCase().includes(value.toLowerCase()) )
  //     setDatas(data)

  //     console.log({data},'SEARCH')
  // }

  const schema = yup.object({
    fromDate: yup.string().required("From date required"),
    toDate: yup.string().required("To date required"),
  });

  const {
    register,
    handleSubmit,
    setFieldValue,
    control,
    formState: { errors },
    reset,
    setValue,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setFilterText("");
        setDatas(orderList)
      }
    };

    const filterOrders = (data) => {
      // let datas = {
      //     fromDate: moment(data?.fromDate, "YYYY-MM-DD").format("DD-MM-YYYY"),
      //     toDate: moment(data?.toDate, "YYYY-MM-DD").format("DD-MM-YYYY")
      // }
      // if (data.toDate === "") {
      //     data.toDate = null
      //     toast.warn(`ToDate is Required`);
      //     return false
      // }
      // if (data.fromDate === "") {
      //     data.fromDate = null
      //     toast.warn(`FromDate is Required`);
      //     return false
      // }

      dispatch(filterOrder(data));
    };

    const resetFun = () => {
      window.location.reload();
      dispatch(getOrderList(1));
    };

    return (
      <div
        style={{ display: "flex", justifyContent: "space-between", flex: 1 }}
      >
        <Row style={{ marginRight: 50 }}>
          <Col>
            <CustomInput
              fieldLabel="From Date"
              control={control}
              error={errors.fromDate}
              fieldName="fromDate"
              type={"date"}
            />
          </Col>
          <Col>
            <CustomInput
              fieldLabel="To Date"
              control={control}
              error={errors.toDate}
              fieldName="toDate"
              type={"date"}
            />
          </Col>
          <Col style={{ paddingTop: 35 }}>
            <Button
              type="button"
              color="primary"
              onClick={handleSubmit(filterOrders)}
            >
              Submit
            </Button>
          </Col>
          <Col style={{ paddingTop: 35 }}>
            <Button type="button" color="primary" onClick={resetFun}>
              Reset
            </Button>
          </Col>
        </Row>
        <SearchHeader
          onFilter={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
        />
      </div>
    );
  }, [filterText]);

  var columns = [
    {
      name: <b>{Capitalize("OrderId")}</b>,
      header: <b>{Capitalize("Order Id")}</b>,
      selector: (row) => `#ORD${row?.order_id}`,
      style: {
        textAlign: "center",
      },
    },
    {
      name: <b>{Capitalize("Order Date")}</b>,
      header: <b>{Capitalize("Order Date")}</b>,
      selector: (row) => `${moment(row?.orderDate).format("DD-MM-YYYY")}`,
      style: {
        textAlign: "center",
      },
    },
    {
      name: <b>{Capitalize("Store")}</b>,
      header: <b>{Capitalize("Store")}</b>,
      selector: (row) => row?.storeName ? row?.storeName : "-",
      style: {
        textAlign: "center",
      },
    },
    {
      name: <b>{Capitalize("Customer Name")}</b>,
      header: <b>{Capitalize("Customer Name")}</b>,
      selector: (row) => row?.customer?.name,
      style: {
        textAlign: "center",
      },
    },
    {
      name: <b>{Capitalize("mobile")}</b>,
      header: <b>{Capitalize("Mobile No")}</b>,
      selector: (row) => row?.shippingAddress?.mobile,
      style: {
        textAlign: "center",
      },
    },
    // {
    //     name: <b>{Capitalize("Email")}</b>,
    //     header: <b>{Capitalize("Email")}</b>,
    //     selector: row => row?.billingAddress?.email,
    //     style: {
    //         textAlign: "center",
    //     },
    // },
    {
      name: <b>{Capitalize("payment Type")}</b>,
      header: <b>{Capitalize("payment Type")}</b>,
      selector: (row) => row?.paymentType,
      style: {
        textAlign: "center",
      },
    },
    // {
    //   name: <b>{Capitalize("Payment Status")}</b>,
    //   header: <b>{Capitalize("paymentType")}</b>,
    //   selector: (row) => row?.paymentStatus,
    //   style: {
    //     textAlign: "center",
    //   },
    // },

    {
      name: <b>{Capitalize("Payment Status")}</b>,
      header: <b>{Capitalize("paymentType")}</b>,
      selector: (row) => {
        if (row?.paymentStatus === "captured") {
          return "paid"; // Use return to send the value
        } else {
          return row?.paymentStatus; // Return the original status
        }
      },
      style: {
        textAlign: "center",
      },
    },

    // {
    //     name: <b>{Capitalize("razorPayId")}</b>,
    //     header: <b>{Capitalize("razorPayId")}</b>,
    //     selector: row => row?.razorPayId,
    //     style: {
    //         textAlign: "center",
    //     },
    // },
    {
      name: <b>{Capitalize("total")}</b>,
      header: <b>{Capitalize("total")}</b>,
      selector: (row) => `AED ${parseFloat(row?.total).toFixed(2)}`,
      style: {
        textAlign: "center",
      },
    },
    {
      name: <b>{Capitalize("Slot Date")}</b>,
      header: <b>{Capitalize("Slot Date")}</b>,
      // selector: row => row?.slot_date && row?.slot_date !== 'Invalid date'? moment(row?.slot_date).format('DD-MM-YYYY') : '-',
      selector: (row) => {
        const dateFormat = "YYYY-MM-DD HH:mm";
        const parsedDate = moment(row?.slot_date, dateFormat, true);
        if (parsedDate.isValid()) {
          return moment(row?.slot_date, "YYYY-MM-DD HH:mm").format(
            "DD-MM-YYYY"
          );
        } else {
          return "-";
        }
      },
      style: {
        textAlign: "center",
      },
    },
    {
      name: <b>{Capitalize("Slot FromTime")}</b>,
      header: <b>{Capitalize("Slot FromTime")}</b>,
      selector: (row) => row?.delivery_slot?.fromTime ? row?.delivery_slot?.fromTime : "-",
      style: {
        textAlign: "center",
      },
    },
    {
      name: <b>{Capitalize("Slot ToTime")}</b>,
      header: <b>{Capitalize("Slot ToTime")}</b>,
      selector: (row) => row?.delivery_slot?.toTime ? row?.delivery_slot?.toTime : "-",
      style: {
        textAlign: "center",
      },
    },
    // {
    //   name: <b>{Capitalize("Order status")}</b>,
    //   header: <b>{Capitalize("orderStatus")}</b>,
    //   selector: (row) => row?.orderStatus,
    //   style: {
    //     textAlign: "center",
    //   },
    // },
    {
      name: <b>{Capitalize("Order status")}</b>,
      header: <b>{Capitalize("orderStatus")}</b>,
      selector: (row) => {
        if (row?.orderStatus === "paid") {
          return "created"; // Use return to send the value
        } else {
          return row?.orderStatus; // Return the original status
        }
      },
      style: {
        textAlign: "center",
      },
    },

    {
      name: <b>Actions</b>,
      id: "delete",
      accessor: (str) => "delete",
      style: {
        textAlign: 'center',
      },
      cell: (row, index) => (
        <div>
          <div>
            <span onClick={() => Navigatedetailpage(row?._id)}>
              <i
                className="fa fa-eye"
                style={{
                  width: 35,
                  fontSize: 20,
                  padding: 11,
                  color: "#e4566e",
                  cursor: "pointer",
                }}
              ></i>
            </span>

            <span onClick={() => handleEdit(row)}>
              <i
                className="fa fa-pencil"
                style={{
                  width: 35,
                  fontSize: 20,
                  padding: 11,
                  color: "rgb(40, 167, 69)",
                  cursor: "pointer",
                }}
              ></i>
            </span>
          </div>
          {(row?.orderStatus?.toLowerCase() !== "cancelled" && row?.orderStatus?.toLowerCase() !== "delivered") && <div>
            {/* <span>{row?.orderStatus}</span> */}
           <span onClick={() => handleAssign(row)}>
            <div
              style={{
                background: row?.rider ? "#f71c1b" : "#0f02ff",
                padding: 5,
                borderRadius: 5,
                cursor: "pointer",
                
              }}
            >
              <p style={{ color: "#fff", fontSize: 12, textAlign: 'center' }}>
                {row?.rider ? "RE-ASSIGN" : "ASSIGN"}
              </p>
            </div>
          </span>
          </div>}
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (orderupdated) {
      toast.success(`Order Updated Successfully`);
      dispatch({
        type: RESET_ERROR,
      });
    }
  }, [orderupdated]);

  useEffect(() => {
    if (error) {
      toast.error(`${error.message}`);
      dispatch({
        type: RESET_ERROR,
      });
    }
  }, [error]);

  useEffect(() => {
    dispatch(getOrderList(1));
  }, []);

  const handlePageChange = (page) => {
    dispatch(getOrderList(page));
  };

  // const handlePerRowsChange = async (newPerPage, page) => {
  // 	setLoading(true);

  // 	const response = await axios.get(`https://reqres.in/api/users?page=${page}&per_page=${newPerPage}&delay=1`);

  // 	setData(response.data.data);
  // 	setPerPage(newPerPage);
  // 	setLoading(false);
  // };

  const paginationComponentOptions = {
    noRowsPerPage: true,
  };

  console.log(orderId);
  

  useEffect(() => { 
		getOrderStatus()
	}, [])

  return (
    <Fragment>
      <Breadcrumb title="Order List" parent="Order" />
      {/* <!-- Container-fluid starts--> */}
      <Container fluid="true">
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>Order Details</h5>
              </CardHeader>
              <CardBody>
                <div className="clearfix"></div>
                <div id="basicScenario" className="product-physical">
                  <Fragment>
                    <DataTable
                      pagination
                      title=""
                      columns={columns}
                      progressPending={loading}
                      //paginationTotalRows={orderList?.total}
                      //onChangeRowsPerPage={handlePerRowsChange}
                      progressComponent={
                        <Spinner animation="grow" variant="info" />
                      }
                      //paginationComponentOptions={paginationComponentOptions}
                      //onChangePage={handlePageChange}
                      data={datas}
                      fixedHeader
                      subHeader
                      subHeaderComponent={subHeaderComponentMemo}
                    />
                  </Fragment>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <AssignRiderModal
        openModal={assign}
        onCloseModal={() => {
          setAssign(false);
        }}
        data={activeData}
        order_id={orderId}
        rider={rider}
        store={store}
        assign={assign}
      />

      <OrderStatusModal
        openModal={open}
        onCloseModal={() => {
          setOpen(false);
        }}
        data={activeData}
      />
    </Fragment>
  );
};

export default OrderDetails;
